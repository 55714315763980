import { render, staticRenderFns } from "./DocChangesHistory.vue?vue&type=template&id=662d495e&scoped=true&"
import script from "./DocChangesHistory.vue?vue&type=script&lang=ts&"
export * from "./DocChangesHistory.vue?vue&type=script&lang=ts&"
import style0 from "./DocChangesHistory.vue?vue&type=style&index=0&id=662d495e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662d495e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VSimpleTable,VTooltip})
